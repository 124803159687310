import { useState } from "react";
import { Container, Select, MenuItem } from "@mui/material";
import MessageContainer from "./container/messageContainer";
import MessageInput from "./input/messageInput";
import Navbar from "./navbar/navbar";
const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [feedback, setFeedback] = useState(0);
  const [sessionId, setSessionId] = useState("");
  const [loading, setLoading] = useState(false);
  const [userLang, setUserLang] = useState("en");

  // Function to translate text using the translation API
  const translateText = async (text, sourceLang, targetLang) => {
    try {
      const translateApiUrl = process.env.REACT_APP_TRANSLATE_API;
      console.log(
        `Translating text from ${sourceLang} to ${targetLang} via ${translateApiUrl}`
      );

      const response = await fetch(translateApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: text,
          sourceLang: sourceLang,
          targetLang: targetLang,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to translate text:", errorText);
        throw new Error("Failed to translate text");
      }

      const data = await response.json();
      return data.translatedText;
    } catch (error) {
      console.error("Error translating text:", error);
      throw error;
    }
  };

  const [requiresGender, setRequiresGender] = useState(false);
  const [query, setQuery] = useState("");
  const summarizeMessages = async () => {
    try {
      const summaryApiUrl = process.env.REACT_APP_SUMMARY_API;
      console.log(`Sending request to summarize messages at ${summaryApiUrl}`);
      const summaryResponse = await fetch(summaryApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: sessionId,
        }),
      });
      if (!summaryResponse.ok) {
        const errorText = await summaryResponse.text();
        console.error("Failed to summarize messages:", errorText);
        throw new Error("Failed to summarize messages");
      }

      const summaryData = await summaryResponse.json();
      console.log("Summary data received:", summaryData);
      return summaryData.summary;
    } catch (error) {
      console.error("Error summarizing messages:", error);
      return "";
    }
  };
  const handleSendMessage = async (message, handleCategory) => {
    const senderMessage = {
      sender: "user",
      senderID: "test1",
      senderType: "admin",
      text: message,
    };
    setLoading(true);
    let updatedMessages = requiresGender ? messages.slice(0, -1) : messages;
    updatedMessages.push(senderMessage);
    setMessages(updatedMessages);
    try {
      // Step 1: Translate the user's message to English
      let translatedQuery = message;
      if (userLang !== "en") {
        translatedQuery = await translateText(message, userLang, "en");
        console.log("Translated query to English:", translatedQuery);
      }
      let summary = "";
      if (updatedMessages.length === 0) {
        summary = "";
      } else {
        // summary = await summarizeMessages();
      }

      const combinedMessage = `${translatedQuery}`;
      const apiUrl = process.env.REACT_APP_CHAT_API;
      console.log(`Sending chat request to ${apiUrl}`);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: combinedMessage,
          sessionId: sessionId === "" ? null : sessionId,
          summary: summary,
          handleCategory: handleCategory,
        }),
      });

      setLoading(false);
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Network response was not ok:", errorText);
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Chat API data received:", data);

      const chatResponse = data.generated_text;
      if (chatResponse.isGenderRequired) {
        setRequiresGender(true);
        setQuery(chatResponse.query.split("User Query: ")[1]);
      } else {
        setRequiresGender(false);

        const formattedResponse = chatResponse.startsWith('"')
          ? JSON.parse(chatResponse)
          : chatResponse;

        let translatedResponse = formattedResponse;
        if (userLang !== "en") {
          translatedResponse = await translateText(
            formattedResponse,
            "en",
            userLang
          );
          console.log(
            "Translated bot response to user language:",
            translatedResponse
          );
        }

        const botMessage = {
          sender: "bot",
          text: translatedResponse,
          productDetails: data.productDetails,
        };

        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text: translatedResponse },
        ]);
        await saveChatSession(senderMessage, botMessage, feedback);
      }
    } catch (error) {
      console.error("Error handling message:", error);
      setLoading(false);
    }
  };

  const saveChatSession = async (senderMessage, botMessage, feedback) => {
    try {
      const apiUrlSaveSession = process.env.REACT_APP_SAVE_CHAT_API;
      console.log(`Saving chat session to ${apiUrlSaveSession}`);

      const response = await fetch(apiUrlSaveSession, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: sessionId,
          userMessage: senderMessage,
          botResponse: botMessage,
          responseFeedback: feedback,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error saving chat session:", errorText);
        throw new Error("Error saving chat session");
      }

      const data = await response.json();
      console.log("Chat session saved successfully:", data);
      setSessionId(data.id);
    } catch (error) {
      console.error("Error saving chat session:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Error generating response" },
      ]);
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 2,
        height: "100vh",
      }}
    >
      <Navbar />
      <MessageContainer
        messages={messages}
        feedback={feedback}
        isGenderRequired={requiresGender}
        query={query}
        onFeedbackChange={setFeedback}
        handleSend={handleSendMessage}
        loading={loading}
        setRequiresGender={setRequiresGender}
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "95%",
          }}
        >
          <MessageInput handleSend={handleSendMessage} />
        </div>

        <Select
          value={userLang}
          onChange={(e) => setUserLang(e.target.value)}
          variant="outlined"
          sx={{
            marginLeft: "10px",
            color: "white",
            borderColor: "white",
            outline: 2,
            outlineColor: "white",
          }}
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="hn">Hindi</MenuItem>
          {/* Indic languages */}
          <MenuItem value="bn">Bengali</MenuItem>
          <MenuItem value="te">Telugu</MenuItem>
          <MenuItem value="mr">Marathi</MenuItem>
          <MenuItem value="ta">Tamil</MenuItem>
          <MenuItem value="ur">Urdu</MenuItem>
          <MenuItem value="gu">Gujarati</MenuItem>
          <MenuItem value="ml">Malayalam</MenuItem>
          <MenuItem value="kn">Kannada</MenuItem>
          <MenuItem value="pa">Punjabi (Gurmukhi)</MenuItem>
          <MenuItem value="as">Assamese</MenuItem>
          <MenuItem value="or">Odia</MenuItem>
          <MenuItem value="sa">Sanskrit</MenuItem>
          {/* Foreign Languages  */}
          <MenuItem value="es">Spanish</MenuItem>
          <MenuItem value="fr">French</MenuItem>
          <MenuItem value="de">German</MenuItem>
          <MenuItem value="zh">Chinese</MenuItem>
          <MenuItem value="ar">Arabic</MenuItem>
          <MenuItem value="pt">Portuguese</MenuItem>
          <MenuItem value="ru">Russian</MenuItem>
          <MenuItem value="ja">Japanese</MenuItem>
          <MenuItem value="ko">Korean</MenuItem>
          <MenuItem value="it">Italian</MenuItem>
          <MenuItem value="nl">Dutch</MenuItem>
          <MenuItem value="sv">Swedish</MenuItem>
          <MenuItem value="tr">Turkish</MenuItem>
          <MenuItem value="el">Greek</MenuItem>
          <MenuItem value="he">Hebrew</MenuItem>
          <MenuItem value="pl">Polish</MenuItem>
          <MenuItem value="uk">Ukrainian</MenuItem>
          <MenuItem value="vi">Vietnamese</MenuItem>
          <MenuItem value="th">Thai</MenuItem>
          <MenuItem value="id">Indonesian</MenuItem>
          <MenuItem value="ms">Malay</MenuItem>
        </Select>
      </div>
    </Container>
  );
};

export default Chat;
