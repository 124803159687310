import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { IconButton } from "@mui/material";

const options = [
  { key: "Hindi", value: "hi-IN" },
  { key: "Bengali", value: "bn-IN" },
  { key: "Kannada", value: "kn-IN" },
  { key: "Malayalam", value: "ml-IN" },
  { key: "Marathi", value: "mr-IN" },
  { key: "Odia", value: "od-IN" },
  { key: "Punjabi", value: "ps-IN" },
  { key: "Tamil", value: "ta-IN" },
  { key: "Telugu", value: "te-IN" },
  { key: "Gujarati", value: "gu-IN" },
];

function LanguageDialog(props) {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const radioGroupRef = useRef(null);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose(valueProp);
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435,backgroundColor:"rgb(185 184 184)" } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Select Language</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="language"
          name="language"
          value={value}
          onChange={handleChange}
          sx={{
            "& .css-1qeilas-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
              color: "#212121",
            },
          }}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option.value}
              key={option.key}
              control={<Radio />}
              label={option.key}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ backgroundColor: "#212121" }}
          autoFocus
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button style={{ backgroundColor: "#212121" }} onClick={handleOk}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

LanguageDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default function VoiceInput({
  onTranscript,
  setIsRecording,
  isRecording,
  mediaRecorder,
  setMediaRecorder,
  isDeleted,
  setIsDeleted,
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("hi-IN");
  const audioChunks = useRef([]);
  const timeoutRef = useRef(null);
  const [stream, setStream] = useState(null);

  useEffect(() => {
    if (isDeleted && isRecording) {
      stopRecording();
      setIsDeleted(false);
    }
  }, [isDeleted, isRecording]);

  const startRecording = async (newValue) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);
      setStream(stream);
      audioChunks.current = [];

      recorder.ondataavailable = (event) => {
        audioChunks.current.push(event.data);
      };
      recorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
        const form = new FormData();
        form.append("file", audioBlob, "audio.wav");
        form.append("language_code", newValue);
        form.append("model", "saarika:v1");

        const options = {
          method: "POST",
          headers: {
            "api-subscription-key": process.env.REACT_APP_SARVAM_AI_API_KEY,
          },
          body: form,
        };

        try {
          const response = await fetch(
            "https://api.sarvam.ai/speech-to-text",
            options
          );
          const data = await response.json();
          if (data && data.transcript) {
            onTranscript(data.transcript);
          } else {
            console.error("Failed to retrieve transcript");
          }
        } catch (error) {
          console.error("Error during speech-to-text conversion:", error);
        } finally {
          clearTimeout(timeoutRef.current);
          setIsRecording(false);
        }
      };

      recorder.start();
      setIsRecording(true);

      timeoutRef.current = setTimeout(() => {
        stopRecording();
      }, 9000);
    } catch (err) {
      console.error("Error starting recording:", err);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      if (isDeleted) {
        mediaRecorder.ondataavailable = null;
        mediaRecorder.onstop = null;
        mediaRecorder.stop();
        setIsRecording(false);
        stream.getTracks().forEach((track) => track.stop());
        return;
      }
      mediaRecorder.stop();
      setIsRecording(false);
      stream.getTracks().forEach((track) => track.stop());
    }
  };

  const handleToggleRecording = (newValue) => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording(newValue);
    }
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
      handleToggleRecording(newValue);
    }
  };
  return (
    <>
      <IconButton
        style={{
          backgroundColor: isRecording ? "#f44336" : "#e3e3e3",
          marginRight: "10px",
        }}
        onClick={() => {
          if (!isRecording) {
            setOpen(true);
          } else {
            handleToggleRecording();
          }
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          fill="currentColor"
        >
          <path
            fill="#2f2f2f"
            d="M12 15c1.66 0 2.99-1.34 2.99-3L15 6c0-1.66-1.34-3-3-3S9 4.34 9 6v6c0 1.66 1.34 3 3 3m5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 15 6.7 12H5c0 3.42 2.72 6.23 6 6.72V22h2v-3.28c3.28-.48 6-3.3 6-6.72z"
          ></path>
        </svg>
      </IconButton>
      <LanguageDialog
        id="language-menu"
        keepMounted
        open={open}
        onClose={handleClose}
        value={value}
      />
    </>
  );
}
