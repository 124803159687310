import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import Logo from "../../assets/Logo.png";
import { useTheme } from "@emotion/react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import IconButton from "@mui/material/IconButton";
import thumbsUpIcon from "../../assets/thumbsUpIcon.svg";
import thumbsUpIconFilled from "../../assets/thumbsUpIconFilled.svg";
import thumbsDownIcon from "../../assets/thumbsDownIcon.svg";
import thumbsDownIconFilled from "../../assets/thumbsDownIconFilled.svg";
import copyIcon from "../../assets/copyIcon.svg";
import logo from "../../assets/Logo.svg";
import PulseLoader from "react-spinners/PulseLoader";
const MarkdownImage = ({ src, alt }) => {
  const [imageLoaded, setImageLoaded] = useState(true);
  return imageLoaded ? (
    <img
      src={src}
      alt={alt}
      style={{
        width: "200px",
        height: "240px",
        display: "block",
        margin: "10px 0",
      }}
      onError={() => setImageLoaded(false)}
    />
  ) : null;
};
const MarkdownLink = ({ href, children }) => (
  <Link
    href={href}
    onError={(e) => (e.target.style.display = "none")}
    target="_blank"
    rel="noopener noreferrer"
    color="#7ab7ff"
  >
    {children}
  </Link>
);
const renderers = {
  img: MarkdownImage,
  a: MarkdownLink,
};

const retailerQuestions = require("./retailers");
const fieldSalesQuestions = require("./fieldSales");
const employeeQuestions = require("./employees");
const customerQuestions = require("./customers");

const getRandomQuestion = (category) => {
  let remainingQuestions;
  if (category === "retailers") {
    remainingQuestions = [...retailerQuestions];
    if (remainingQuestions?.length === 0) {
      remainingQuestions = [...retailerQuestions];
    }
  } else if (category === "employees") {
    remainingQuestions = [...employeeQuestions];
    if (remainingQuestions?.length === 0) {
      remainingQuestions = [...employeeQuestions];
    }
  } else if (category === "customers") {
    remainingQuestions = [...customerQuestions];
    if (remainingQuestions?.length === 0) {
      remainingQuestions = [...customerQuestions];
    }
  } else if (category === "fieldSales") {
    remainingQuestions = [...fieldSalesQuestions];
    if (remainingQuestions?.length === 0) {
      remainingQuestions = [...fieldSalesQuestions];
    }
  }

  const randomIndex = Math.floor(Math.random() * remainingQuestions?.length);
  const question = remainingQuestions[randomIndex];
  remainingQuestions.splice(randomIndex, 1);
  return question;
};

const styles = {
  suggestionsDiv: {
    border: "1px solid hsl(0deg 0% 100% / 10%)",
    backgroundColor: "inherit",
    cursor: "pointer",
    color: "rgb(155 155 155)",
    padding: "10px",
    borderRadius: "7px",
  },
};

function MessageContainer({
  messages,
  onFeedbackChange,
  handleSend,
  loading,
  isGenderRequired,
  setRequiresGender,
  query,
}) {
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const [feedback, setFeedback] = useState({});
  const lastMessageRef = useRef(null);
  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleGenderSelect = (gender) => {
    const lastMessage = messages[messages.length - 1]?.text.trim() || "";
    const updatedLastMessage = lastMessage.endsWith("?")
      ? lastMessage.slice(0, -1).trim()
      : lastMessage;

    const updatedMessage = gender.length
      ? `${updatedLastMessage} for ${gender}`
      : updatedLastMessage;

    const genderCategory = gender ? "" : "no specific category";
    handleSend(updatedMessage, genderCategory);
    setRequiresGender(false);
  };

  const handleFeedback = (index, feedbackType) => {
    setFeedback((prevFeedback) => {
      const updatedFeedback = {
        ...prevFeedback,
        [index]: feedbackType === prevFeedback[index] ? 0 : feedbackType,
      };
      onFeedbackChange(updatedFeedback[index]);
      return updatedFeedback;
    });
  };
  const handleRandom = (category) => {
    const randomQuestion = getRandomQuestion(category);
    handleSend(randomQuestion);
  };

  return (
    <Container
      sx={{
        flexGrow: 1,
        width: isMediumDown ? "100%" : "80%",
        display: "flex",
        flexDirection: "column",
        maxWidth: isMediumDown ? "100%" : "80%",
      }}
    >
      {messages.length !== 0 ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: isMediumDown ? "column" : "row",
              gap: "10px",
              paddingBottom: "30px",
              justifyContent: "center",
              position: "sticky",
              top: isMediumDown ? "78px" : "90px",
              zIndex: theme.zIndex.drawer + 1,
              backgroundColor: "#212121",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <div
                style={{
                  ...styles.suggestionsDiv,
                  fontSize: isMediumDown ? "12px" : "16px",
                  fontWeight: "bolder",
                }}
                onClick={() => {
                  handleRandom("fieldSales");
                }}
              >
                Field Sales:
              </div>
              <div
                style={{
                  ...styles.suggestionsDiv,
                  fontSize: isMediumDown ? "12px" : "16px",
                  fontWeight: "bolder",
                }}
                onClick={() => {
                  handleRandom("retailers");
                }}
              >
                Retailers:
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <div
                style={{
                  ...styles.suggestionsDiv,
                  fontSize: isMediumDown ? "12px" : "16px",
                  fontWeight: "bolder",
                }}
                onClick={() => {
                  handleRandom("customers");
                }}
              >
                Customers:
              </div>
              <div
                style={{
                  ...styles.suggestionsDiv,
                  fontSize: isMediumDown ? "12px" : "16px",
                  fontWeight: "bolder",
                }}
                onClick={() => {
                  handleRandom("employees");
                }}
              >
                Employees:
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "90px" }}>
            {messages.map((message, index) => (
              <Box
                key={index}
                sx={{
                  marginBottom: "25px",
                }}
                ref={index === messages.length - 1 ? lastMessageRef : null}
              >
                <div
                  style={{
                    alignItems: "flex-start",
                    display: "flex",
                    justifyContent:
                      message.sender !== "user" ? "flex-start" : "flex-end",
                    gap: "15px",
                  }}
                >
                  {message.sender !== "user" && (
                    <Box>
                      <img src={Logo} alt="Jockey Logo" width="20px" />
                    </Box>
                  )}

                  <Typography
                    variant="body1"
                    sx={{
                      display: "inline-block",
                      padding: message.sender === "user" ? "6px 11px" : 0,
                      borderRadius: "20px",
                      backgroundColor:
                        message.sender === "user" ? "#2f2f2f" : "",
                      color: "rgb(244 244 244)",
                      maxWidth: isMediumDown ? undefined : "80%",
                      lineHeight:
                        message.sender === "user" ? "1.2rem" : "1.5rem",
                      boxSizing: "border-box",
                      margin: 0,
                      whiteSpace: "break-spaces",
                      fontSize: isMediumDown ? "14px" : "1rem",
                    }}
                  >
                    <ReactMarkdown
                      children={message.text}
                      components={{
                        ...renderers,
                        p: ({ node, ...props }) => (
                          <p {...props} style={{ margin: 0 }} />
                        ),
                        ul: ({ node, ...props }) => (
                          <ul
                            {...props}
                            style={{
                              listStyleType: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          />
                        ),
                      }}
                      rehypePlugins={[rehypeRaw]}
                    />
                  </Typography>
                </div>

                {message.sender !== "user" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginLeft: "35px",
                    }}
                  >
                    <IconButton
                      onClick={() => handleFeedback(index, 1)}
                      size="small"
                    >
                      {feedback[index] === 1 ? (
                        <img src={thumbsUpIconFilled} alt="thumbsUpFilled" />
                      ) : (
                        <img src={thumbsUpIcon} alt="thumbsUp" />
                      )}
                    </IconButton>
                    <IconButton
                      onClick={() => handleFeedback(index, -1)}
                      size="small"
                    >
                      {feedback[index] === -1 ? (
                        <img
                          src={thumbsDownIconFilled}
                          alt="thumbsDownFilled"
                        />
                      ) : (
                        <img src={thumbsDownIcon} alt="thumbsDown" />
                      )}
                    </IconButton>
                    <IconButton>
                      <img src={copyIcon} alt="copy" />
                    </IconButton>
                  </div>
                )}
              </Box>
            ))}
            {loading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 2,
                }}
              >
                <PulseLoader color="grey" />
              </Box>
            )}
            {isGenderRequired && (
              <Box
                sx={{
                  color: "rgb(244 244 244)",
                  padding: "16px",
                  borderRadius: "8px",
                  width: "100%",
                  maxWidth: "460px",
                }}
                ref={lastMessageRef}
              >
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    <Typography
                      sx={{ fontWeight: "bold", color: "rgb(244 244 244)" }}
                    >
                      Select your category for accurate product suggestions
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Men"
                      control={
                        <Radio
                          onClick={() => handleGenderSelect("men")}
                          style={{ color: "rgb(244 244 244)" }}
                        />
                      }
                      label="Men"
                    />
                    <FormControlLabel
                      value="Women"
                      control={
                        <Radio
                          onClick={() => handleGenderSelect("women")}
                          style={{ color: "rgb(244 244 244)" }}
                        />
                      }
                      label="Women"
                    />
                    <FormControlLabel
                      value="boys"
                      control={
                        <Radio
                          onClick={() => handleGenderSelect("boys")}
                          style={{ color: "rgb(244 244 244)" }}
                        />
                      }
                      label="Boys"
                    />
                    <FormControlLabel
                      value="girls"
                      control={
                        <Radio
                          onClick={() => handleGenderSelect("girls")}
                          style={{ color: "rgb(244 244 244)" }}
                        />
                      }
                      label="Girls"
                    />
                    <FormControlLabel
                      value="No specific category"
                      control={
                        <Radio
                          onClick={() => handleGenderSelect("")}
                          style={{ color: "rgb(244 244 244)" }}
                        />
                      }
                      label="No specific category"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
          </div>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
            gap: "5%",
            marginTop: isMediumDown ? "22%" : "5%",
          }}
        >
          <img src={logo} alt="Logo" width={isMediumDown ? "15%" : "6%"} />
          <div
            style={{
              color: "rgb(155 155 155)",
              fontWeight: "bolder",
              fontSize: isMediumDown ? "12px" : "16px",
            }}
          >
            Looking for quick tips or need product details on the go? Check out
            our sample questions!!
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: isMediumDown ? "column" : "row",
              gap: "10px",
            }}
          >
            <div
              style={{ ...styles.suggestionsDiv, width: "200px" }}
              onClick={() => {
                handleRandom("fieldSales");
              }}
            >
              <div
                style={{
                  fontSize: isMediumDown ? "12px" : "16px",
                  fontWeight: "bolder",
                }}
              >
                {" "}
                Field Salesperson:{" "}
              </div>
              <div style={{ fontSize: isMediumDown ? "11px" : "14px" }}>
                Need quick product details? Try these sample questions!
              </div>
            </div>
            <div
              style={{ ...styles.suggestionsDiv, width: "200px" }}
              onClick={() => {
                handleRandom("retailers");
              }}
            >
              <div
                style={{
                  fontSize: isMediumDown ? "12px" : "16px",
                  fontWeight: "bolder",
                }}
              >
                {" "}
                Retailers:{" "}
              </div>
              <div style={{ fontSize: isMediumDown ? "11px" : "14px" }}>
                Need quick store details? Try these sample questions!
              </div>
            </div>
            <div
              style={{ ...styles.suggestionsDiv, width: "200px" }}
              onClick={() => {
                handleRandom("customers");
              }}
            >
              <div
                style={{
                  fontSize: isMediumDown ? "12px" : "16px",
                  fontWeight: "bolder",
                }}
              >
                {" "}
                Customers:{" "}
              </div>
              <div style={{ fontSize: isMediumDown ? "11px" : "14px" }}>
                Need quick order details? Try these sample questions!
              </div>
            </div>
            <div
              style={{ ...styles.suggestionsDiv, width: "200px" }}
              onClick={() => {
                handleRandom("employees");
              }}
            >
              <div
                style={{
                  fontSize: isMediumDown ? "12px" : "16px",
                  fontWeight: "bolder",
                }}
              >
                {" "}
                Employees:{" "}
              </div>
              <div style={{ fontSize: isMediumDown ? "11px" : "14px" }}>
                Need quick work details ? Try these sample questions!
              </div>
            </div>
          </div>
        </Box>
      )}
    </Container>
  );
}

export default MessageContainer;
